import React, { useEffect, useReducer } from "react";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LiveCard from "../../components/LiveCard";
import {
  INITIAL_STATE,
  reducer,
  SignSpace,
  QRCode,
} from "../../state/digital-signage";
import {
  GetSignageData,
  refreshHourUI,
  getSubscriptionManager,
  Domains,
} from "../../network";
import { pageHorizontalPadding } from "../../theme";
import { useInterval } from "../../hooks/useInterval";
import CenteredLogo from "../../components/CenteredLogo";
import QR from "../../components/QR";
import { Props as QRCodeProps } from "../../components/QR";
import { genericError } from "../../copy";

type Props = Record<string, never>;

const DigitalSign: React.FC<Props> = () => {
  const theme = useTheme();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const location = useLocation();
  const { pathname } = location;
  const splitPath = pathname.split("/");
  const uri = splitPath.length ? splitPath[splitPath.length - 1] : "";

  const {
    pageState: { isLoading, errorMessage, data },
    title,
    qrCode,
    hideCapacity,
    msUntilRefreshHours,
    documentTitle,
    connectToRealtime,
  } = state;

  const cardWrapperStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(400px, 1fr))",
    justifyContent: "space-between",
    alignItems: "start",
    gap: 10,
    mt: 3,
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(2, 440px)",
    },
  };

  useEffect(() => {
    const hostname = window.location.hostname;
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (hostname.includes(Domains.Waitz)) {
      link.type = "image/png";
      link.href = `waitz.png`;
    } else if (hostname.includes(Domains.Occuspace)) {
      link.type = "image/x-icon";
      link.href = `occuspace.ico`;
    }
  }, []);

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  useEffect(() => {
    GetSignageData(uri, dispatch);
  }, []);

  useInterval(() => {
    refreshHourUI(uri, dispatch);
  }, msUntilRefreshHours);

  useEffect(() => {
    const subscriptionManager = getSubscriptionManager(uri, dispatch);
    const subscription = subscriptionManager();
    return () => subscription.unsubscribe();
    // subscriptionManager will toggle connectToRealtime when an error occurs
    // This is done to reconnect after an error
  }, [connectToRealtime]);

  if (!uri) {
    return <CenteredLogo />;
  }

  if (errorMessage) {
    return <CenteredLogo message={errorMessage} />;
  }

  if (isLoading && !data) {
    return <CenteredLogo message="Loading..." />;
  }

  if (!data) {
    return <CenteredLogo message={genericError} />;
  }

  return (
    <>
      <Box
        sx={{
          py: 10,
          position: "relative",
          px: pageHorizontalPadding,
        }}
      >
        <Typography variant="h1">{title}</Typography>
        <Box sx={cardWrapperStyles}>
          {data?.spaces.map((space: SignSpace) => (
            <LiveCard
              signSpace={{ ...space }}
              key={space.id}
              hideCapacity={hideCapacity}
            />
          ))}
        </Box>
      </Box>
      {(qrCode as QRCode).uri && <QR {...(qrCode as QRCodeProps)} />}
    </>
  );
};

export default DigitalSign;
