import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import QRCode from "react-qr-code";
import Box from "@mui/material/Box";
import Text from "./Text";
import { pageHorizontalPadding } from "../../theme";

export interface Props {
  uri: string;
  label?: string;
}

const qrCodeSizeSmallPx = 120;
const qrCodeSizeLargePx = 200;

const QR: React.FC<Props> = ({ uri, label }) => {
  const theme = useTheme();
  const isHorizontalScreenSize = useMediaQuery(theme.breakpoints.down("lg"));
  const qrCodeSizePx = isHorizontalScreenSize
    ? qrCodeSizeLargePx
    : qrCodeSizeSmallPx;
  const wrapperStyles = {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    pl: pageHorizontalPadding,
    display: "flex",
    alignItems: "center",
    gap: 3,
    [theme.breakpoints.down("lg")]: {
      gap: 6,
      position: "fixed",
      pl: 0,
      bottom: 80,
      pb: 0,
      flexDirection: "column",
      textAlign: "center",
    },
  };

  return (
    <Box sx={wrapperStyles}>
      <QRCode
        size={qrCodeSizePx}
        style={{ height: "auto", width: qrCodeSizePx }}
        value={uri}
        viewBox={`0 0 256 256`}
      />
      {label && (
        <Box>
          <Text>{label}</Text>
          <Text typographyProps={{ color: "secondary.main" }}>{uri}</Text>
        </Box>
      )}
    </Box>
  );
};

export default QR;
