import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { genericError } from "../../copy";
import CenteredLogo from "../CenteredLogo";

interface Props {
  children: React.ReactChild;
}

function errorHandler(error: Error, info: { componentStack: string }): void {
  if (
    process.env.NODE_ENV !== "production" ||
    process.env.IS_STAGING === "true"
  ) {
    // eslint-disable-next-line no-console
    console.log(error);
    // eslint-disable-next-line no-console
    console.log(error.stack);
    // eslint-disable-next-line no-console
    console.log(info?.componentStack);
  }
}

function ErrorFallback() {
  return <CenteredLogo message={genericError} />;
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={errorHandler}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
