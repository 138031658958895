import type {} from "@mui/lab/themeAugmentation"; // Used to theme lab components
import { createTheme } from "@mui/material/styles";

const SPACING = 4;

const backgroundColor = "#f8f8fb";
const cardBoxShadow =
  "0 -20px 20px 0 rgb(255 255 255 / 30%), 0 20px 20px -8px rgb(0 0 0 / 10%)";
const cardBorderColor = "rgba(25, 60, 77, 0.05)";

const blue = "#1c5efc";
const green = "#219653";
const lightGreen = "#02a105";
const yellow = "#f2994a";
const red = "#e13d3d";
const lightGrey = "#ebeced";
const darkGrey = "#555555";

const progressBarBorderRadius = 20;
export const pageHorizontalPadding = 15;

let theme = createTheme();
theme = createTheme({
  spacing: SPACING,
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: 36,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          "-webkit-font-smoothing": "unset",
          height: "100vh",
        },
        body: {
          height: "100vh",
          "& #root": {
            height: "100%",
            width: "100%",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: cardBoxShadow,
          border: `1px solid ${cardBorderColor}`,
          borderRadius: 8,
          position: "relative",
          overflow: "visible",
          height: "260px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: SPACING * 6,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 12,
          borderRadius: progressBarBorderRadius,
          backgroundColor: lightGrey,
        },
        bar: {
          borderRadius: progressBarBorderRadius,
        },
      },
    },
  },
  palette: {
    secondary: {
      main: blue,
    },
    background: {
      default: backgroundColor,
    },
    success: {
      light: lightGreen,
      main: green,
    },
    warning: {
      main: yellow,
    },
    error: {
      main: red,
    },
    grey: {
      500: darkGrey,
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeightMedium: 500,
    h1: {
      fontSize: "3.625rem",
      lineHeight: 1.2,
      fontWeight: 500,
    },
    h2: {
      fontSize: "2.25rem",
      lineHeight: 1.2,
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.2,
    },
  },
});

export default theme;
