import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import theme from "./theme";
import ErrorBoundary from "./components/ErrorBoundary";
import DigitalSign from "./views/DigitalSign";
import ApolloClient from "./ApolloClient";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <ErrorBoundary>
          <ApolloProvider client={ApolloClient}>
            <Router>
              <Routes>
                <Route path="/" element={<DigitalSign />} />
                <Route path="/:uri" element={<DigitalSign />} />
              </Routes>
            </Router>
          </ApolloProvider>
        </ErrorBoundary>
      </CssBaseline>
    </ThemeProvider>
  );
}
