import { Dispatch } from "react";
import { debounce } from "lodash";
import client from "./ApolloClient";
import { gql } from "@apollo/client";
import { Action, ActionType } from "./state/digital-signage";
import { genericError, noSpaceText, notFoundText } from "./copy";

export enum Domains {
  Waitz = "waitz.io",
  Occuspace = "occuspace.io",
}

enum SignageError {
  NotFound = "SIGN_NOT_FOUND",
  NoSpaces = "NO_SPACES",
  Unexpected = "UNEXPECTED",
}

const apiEndpoint = process.env.API_ENDPOINT;
const getSignageDataDebounceMs = 5000;
const reconnectToRealtimeMs = 10000;

const GetSignDataQuery = gql`
  query GetSign($uri: String!) {
    GetSign(uri: $uri) {
      hideCapacity
      title
      qrCode {
        label
        uri
      }
      spaces {
        capacity
        displayName
        id
        percentage
        noCounts
      }
    }
  }
`;

const RealtimeSignOccupancy = gql`
  subscription RealtimeSignOccupancy($uri: String!) {
    RealtimeSignOccupancy(uri: $uri) {
      uri
      occupancy {
        capacity
        noCounts
        occupancy
        percentage
        spaceId
      }
    }
  }
`;

// returns a function our live card component can use to reconnect on error
export const getSubscriptionManager = (
  uri: string,
  dispatch: Dispatch<Action>
) => {
  return () => {
    const observer = client.subscribe({
      query: RealtimeSignOccupancy,
      variables: { uri },
    });
    const subscription = observer.subscribe(
      ({ data: { RealtimeSignOccupancy } }) => {
        dispatch({
          type: ActionType.ReceivedRealtimeData,
          value: RealtimeSignOccupancy,
        });
      },
      (error) => {
        if (error && subscription.closed) {
          // refetch page data
          GetSignageData(uri, dispatch);
          setTimeout(() => {
            // retry subscription connection after 10s
            dispatch({
              type: ActionType.ReconnectToRealtimeData,
            });
          }, reconnectToRealtimeMs);
        }
      }
    );
    return subscription;
  };
};

const getOccupancyData = async (variables: { uri: string }) => {
  const response = await client.query({ query: GetSignDataQuery, variables });
  if (response.error) {
    return { error: response.error };
  }
  return { data: response.data.GetSign };
};

export async function getHourData(hash: string) {
  const response = await fetch(apiEndpoint + "/waitz/signage-hours/" + hash);
  if (response.status !== 200) {
    return { error: "Error retrieving hour data" };
  }
  const body = await response.json();
  return { data: body };
}

const getSignageData = async (uri: string, dispatch: Dispatch<Action>) => {
  try {
    dispatch({ type: ActionType.PageDataLoading });
    const [occupancyData, hourData] = await Promise.all([
      getOccupancyData({ uri }),
      getHourData(uri),
    ]);
    if (occupancyData.error || hourData.error) {
      dispatch({
        type: ActionType.PageDataError,
        value: occupancyData.error || hourData.error,
      });
      return;
    }
    dispatch({
      type: ActionType.PageDataLoaded,
      value: { data: occupancyData.data, hourData: hourData.data },
    });
  } catch (e) {
    dispatch({ type: ActionType.PageDataError, value: e });
  }
};

export const GetSignageData = debounce(
  (uri: string, dispatch: Dispatch<Action>) => {
    getSignageData(uri, dispatch);
  },
  getSignageDataDebounceMs,
  { leading: true }
);

export const refreshHourUI = async (
  uri: string,
  dispatch: Dispatch<Action>
) => {
  try {
    dispatch({ type: ActionType.HourDataRefreshing });
    const { error, data } = await getHourData(uri);
    if (error) {
      dispatch({ type: ActionType.HourDataRefreshError });
      return;
    }
    dispatch({ type: ActionType.RefreshedHourDataLoaded, value: data });
  } catch {
    dispatch({ type: ActionType.HourDataRefreshError });
  }
};

export function getSignageErrorMessage(error: string) {
  switch (error) {
    case SignageError.NotFound:
      return notFoundText;
    case SignageError.NoSpaces:
      return noSpaceText;
    case SignageError.Unexpected:
      return genericError;
    default:
      return genericError;
  }
}
