import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HorizontalLogo from "../../icons/HorizontalLogo";

interface Props {
  message?: string;
}

const CenteredLogo: React.FC<Props> = ({ message }) => {
  return (
    <Box
      display="flex"
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={4}
    >
      <HorizontalLogo />
      {message && <Typography color="grey.500">{message}</Typography>}
    </Box>
  );
};

export default CenteredLogo;
