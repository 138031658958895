import { DateTime } from "luxon";
import { AnyVal } from "../interfaces";
import { getSignageErrorMessage, Domains } from "../network";
import { genericError } from "../copy";

const msOneHour = 60 * 60 * 1000;

export interface SpaceOccupancy {
  capacity: number;
  displayName: string;
  id: number;
  percentage: number;
  noCounts: boolean;
}

export interface QRCode {
  uri?: string;
  label?: string;
}

export interface HourResponseItem {
  isOpen: boolean;
  hourSummary: string;
  id: number;
}

interface HourResponseItemMap {
  [id: number]: HourResponseItem;
}

interface SignSpaceMap {
  [id: number]: SignSpace;
}

export interface SignSpaceMetadata {
  status: SignSpaceStatus;
  isLeastBusy: boolean;
  subTitle: string;
}

export interface RealtimeOccupancy {
  capacity: number;
  spaceId: number;
  percentage: number;
  noCounts: boolean;
  occupancy: number;
}

export type SignSpace = SpaceOccupancy &
  HourResponseItem &
  SignSpaceMetadata &
  RealtimeOccupancy;

interface NetworkData {
  spaces: SignSpace[];
  hours: HourResponseItem[];
}

interface NetworkState {
  isLoading: boolean;
  errorMessage: string;
  data: NetworkData | null;
}

export interface State {
  pageState: NetworkState;
  title: string;
  qrCode: QRCode | null;
  hideCapacity: boolean;
  msUntilRefreshHours: number;
  documentTitle: string;
  // boolean value is irrelevant
  // the value is used to trigger a useEffect to run when the subscription disconnects
  connectToRealtime: boolean;
}

export const INITIAL_STATE = {
  pageState: {
    isLoading: true,
    errorMessage: "",
    data: null,
  },
  title: "",
  qrCode: null,
  hideCapacity: false,
  msUntilRefreshHours: msOneHour,
  documentTitle: "",
  connectToRealtime: false,
};

export interface Action {
  type: ActionType;
  value?: AnyVal;
}

export enum SignSpaceStatus {
  NotBusy = "Not Busy",
  Busy = "Busy",
  VeryBusy = "Very Busy",
  Unavailable = "Unavailable",
}

export enum ActionType {
  PageDataLoading,
  PageDataLoaded,
  PageDataError,
  HourDataRefreshing,
  RefreshedHourDataLoaded,
  HourDataRefreshError,
  ReceivedRealtimeData,
  ReconnectToRealtimeData,
}

const getDocumentTitle = (signTitle: string) => {
  const hostname = window.location.hostname;
  let pageTitle = hostname.includes(Domains.Waitz) ? "Waitz" : "Occuspace";
  if (signTitle) {
    pageTitle = `${pageTitle} | ${signTitle}`;
  }
  return pageTitle;
};

// finds how many milliseconds it takes to get to :30 minutes after the hour
export const msUntilNextInterval = () => {
  const currentMinute = DateTime.now().minute;
  const interval = 30;
  if (currentMinute <= 30) {
    return (interval - currentMinute) * 60 * 1000;
  } else {
    return (interval - (currentMinute - interval)) * 60 * 1000;
  }
};

function deriveSignSpaceStatus(
  { percentage, noCounts }: SignSpace,
  { isOpen }: HourResponseItem
) {
  if (noCounts && isOpen) return SignSpaceStatus.Unavailable;
  if (percentage <= 45) {
    return SignSpaceStatus.NotBusy;
  } else if (percentage <= 80) {
    return SignSpaceStatus.Busy;
  } else {
    return SignSpaceStatus.VeryBusy;
  }
}

const mergeOccupancyAndHourData = (
  occupancy: SignSpace[],
  hours: HourResponseItem[]
) => {
  const hourMap = convertToHourMap(hours);
  return occupancy.map((space) => {
    const { id } = space;
    const { hourSummary, isOpen } = hourMap[id];
    return {
      ...space,
      hourSummary,
      isOpen,
    };
  });
};

const mergeUpdatedCardData = (
  currentSpaces: SignSpace[],
  newSpaces: RealtimeOccupancy[]
) => {
  const map = convertToOccupancyMap(currentSpaces);
  const updatedSpaces: SignSpace[] = [];

  newSpaces.forEach((newSpace) => {
    const currentSpace = map[newSpace.spaceId];
    updatedSpaces.push({
      ...currentSpace,
      ...newSpace,
    });
  });
  return updatedSpaces;
};

const convertToHourMap = (hours: HourResponseItem[]): HourResponseItemMap => {
  return hours.reduce((memo, hourItem: HourResponseItem) => {
    return { ...memo, [hourItem.id]: hourItem };
  }, {});
};

const convertToOccupancyMap = (occupancy: SignSpace[]): SignSpaceMap => {
  return occupancy.reduce((memo, occupancyItem: SignSpace) => {
    return { ...memo, [occupancyItem.id]: occupancyItem };
  }, {});
};

const deriveSubtitle = (
  status: SignSpaceStatus,
  { hourSummary }: HourResponseItem
) => {
  return status === SignSpaceStatus.Unavailable
    ? "Data unavailable"
    : hourSummary;
};

const deriveLiveCardMetadata = (
  spaces: SignSpace[],
  hourData: HourResponseItem[]
): SignSpace[] => {
  let min = Infinity;
  let leastBusySpaceIndex = -1;
  const spaceHourDataMap = convertToHourMap(hourData);
  const spacesWithMetaData = spaces.map((space, i) => {
    const { percentage, noCounts, id } = space;
    const spaceHourData = spaceHourDataMap[id];
    const { isOpen } = spaceHourDataMap[id];

    // if this space has a lower occupancy than min, and its open with counts, it is the least busy
    if (percentage < min && isOpen && !noCounts) {
      min = percentage;
      leastBusySpaceIndex = i;
    }
    const status = deriveSignSpaceStatus(space, spaceHourData);
    const subTitle = deriveSubtitle(status, spaceHourData);

    return {
      ...space,
      ...spaceHourData,
      status,
      subTitle,
      isLeastBusy: false,
    };
  });
  if (leastBusySpaceIndex !== -1) {
    spacesWithMetaData[leastBusySpaceIndex].isLeastBusy = true;
  }
  return spacesWithMetaData;
};

const handleLoading = (state: NetworkState) => {
  return {
    ...state,
    isLoading: true,
    errorMessage: "",
  };
};

const handleLoaded = (state: NetworkState, data: NetworkData) => {
  return {
    ...state,
    isLoading: false,
    data,
  };
};

const handleError = (state: NetworkState, message: string) => {
  return {
    ...state,
    errorMessage: message,
    isLoading: false,
  };
};

export function reducer(state: State, { type, value }: Action): State {
  switch (type) {
    case ActionType.PageDataLoading: {
      return {
        ...state,
        pageState: handleLoading(state.pageState),
      };
    }
    case ActionType.PageDataLoaded: {
      const {
        hourData,
        data: { spaces },
      } = value as {
        hourData: HourResponseItem[];
        data: { spaces: SignSpace[] };
      };
      const delay = msUntilNextInterval();
      const spacesWithMetaData = deriveLiveCardMetadata(spaces, hourData);
      return {
        ...state,
        msUntilRefreshHours: delay,
        pageState: handleLoaded(state.pageState, {
          spaces: spacesWithMetaData,
          hours: hourData,
        }),
        title: value.data.title,
        qrCode: value.data.qrCode,
        hideCapacity: value.data.hideCapacity,
        documentTitle: getDocumentTitle(value.data.title),
      };
    }
    case ActionType.PageDataError: {
      const error = getSignageErrorMessage(value.message);
      return {
        ...state,
        pageState: handleError({ ...state.pageState }, error),
      };
    }
    case ActionType.ReceivedRealtimeData: {
      const {
        pageState: { data },
      } = state;
      const combinedSpacesWithMetaData = mergeUpdatedCardData(
        data?.spaces as SignSpace[],
        value.occupancy
      );

      const spacesWithMetaData = deriveLiveCardMetadata(
        combinedSpacesWithMetaData,
        data?.hours as HourResponseItem[]
      );

      return {
        ...state,
        pageState: handleLoaded(state.pageState, {
          spaces: spacesWithMetaData,
          hours: data?.hours as HourResponseItem[],
        }),
      };
    }
    case ActionType.HourDataRefreshing: {
      return {
        ...state,
        pageState: handleLoading(state.pageState),
      };
    }
    case ActionType.RefreshedHourDataLoaded: {
      const {
        pageState: { data },
      } = state;

      const spacesWithHours = mergeOccupancyAndHourData(
        data?.spaces as SignSpace[],
        value
      );

      return {
        ...state,
        msUntilRefreshHours: 30 * 60 * 1000,
        pageState: handleLoaded(state.pageState, {
          spaces: spacesWithHours,
          hours: value,
        }),
      };
    }
    case ActionType.HourDataRefreshError: {
      return {
        ...state,
        pageState: handleError({ ...state.pageState }, genericError),
      };
    }
    case ActionType.ReconnectToRealtimeData: {
      state.connectToRealtime = !state.connectToRealtime;
      return state;
    }
    default:
      throw new Error("digital signage view state unexpected action");
  }
}
