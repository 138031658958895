import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (savedCallback as any).current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (savedCallback as any).current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
