import React from "react";
import Avatar from "@mui/material/Avatar";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

type Props = Record<string, never>;

const boxShadow = "0 4px 12px rgb(0 0 0 / 25%)";
const size = 50;
const offsetTop = -16;
const offsetRight = -18;

const LeastBusyIcon: React.FC<Props> = () => {
  return (
    <Avatar
      sx={{
        position: "absolute",
        top: offsetTop,
        right: offsetRight,
        bgcolor: "success.light",
        boxShadow: boxShadow,
        height: size,
        width: size,
      }}
    >
      <StarRateRoundedIcon sx={{ color: "white", mb: 1 }} fontSize="large" />
    </Avatar>
  );
};

export default LeastBusyIcon;
