import React from "react";
import Typography from "@mui/material/Typography";
import { SignSpace } from "../../state/digital-signage";

interface Props {
  signSpace: SignSpace;
  hideCapacity: boolean;
}

const Text = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      color="grey.500"
      fontSize="1.5rem"
      lineHeight={1.5}
      sx={{
        "&:first-letter": {
          textTransform: "capitalize",
        },
      }}
    >
      {children}
    </Typography>
  );
};

const Subtitle: React.FC<Props> = ({
  signSpace: { isOpen, subTitle, capacity },
  hideCapacity,
}) => {
  if (hideCapacity) {
    return <Text>{subTitle}</Text>;
  }

  if (!isOpen) {
    return (
      <>
        <Text>{subTitle}</Text>
        <Text>Capacity: {capacity}</Text>
      </>
    );
  }

  return (
    <Text>
      {subTitle} |{` `} Capacity: {capacity}
    </Text>
  );
};

export default Subtitle;
