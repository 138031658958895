import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";

interface Props {
  children: React.ReactNode;
  color: TypographyProps["color"];
}

const BusyText: React.FC<Props> = ({ children, color }) => {
  return (
    <Typography color={color} fontSize="1.75rem" lineHeight={1.2}>
      {children}
    </Typography>
  );
};

export default BusyText;
