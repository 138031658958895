import { AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { createHttpLink } from "apollo-link-http";
import { AUTH_TYPE } from "aws-appsync";
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";

const url = process.env.GRAPHQL_ENDPOINT as string;
const region = process.env.AWS_REGION as string;

const apiKey = process.env.GRAPHQL_APIKEY;

const auth = {
  type: AUTH_TYPE.API_KEY,
  apiKey,
} as AuthOptions;

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAuthLink({ url, region, auth }) as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink as any),
]);

// graphQl version ^15.7.0 used to fix error: cannot convert null node_modules/apollo-cache-inmemory/lib/queryKeyMaker.js
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default client as ApolloClient<any>;
