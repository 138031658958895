import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface Props {
  children: React.ReactNode;
  typographyProps?: TypographyProps;
}

const Text: React.FC<Props> = ({ children, typographyProps }) => {
  const theme = useTheme();

  const sx = {
    [theme.breakpoints.down("lg")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
  };

  const props = { sx, ...typographyProps };

  return (
    <Typography lineHeight="1.5" {...props}>
      {children}
    </Typography>
  );
};

export default Text;
