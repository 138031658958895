import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { SignSpace, SignSpaceStatus } from "../../state/digital-signage";
import Subtitle from "./Subtitle";
import BusyText from "./BusyText";
import LeastBusyIcon from "./LeastBusyIcon";

interface Props {
  signSpace: SignSpace;
  hideCapacity: boolean;
}

function deriveThemeColor(status: SignSpaceStatus) {
  switch (status) {
    case SignSpaceStatus.NotBusy:
      return "success";
    case SignSpaceStatus.Busy:
      return "warning";
    case SignSpaceStatus.VeryBusy:
      return "error";
    default:
      return "info";
  }
}

const LiveCard: React.FC<Props> = ({ signSpace, hideCapacity }) => {
  const { percentage, displayName, noCounts, isOpen, status, isLeastBusy } =
    signSpace;
  const themeColor = deriveThemeColor(status);

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          gap: 8,
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h2">{displayName}</Typography>
          <Subtitle signSpace={signSpace} hideCapacity={hideCapacity} />
        </Box>
        {isOpen && !noCounts && (
          <Box display="flex" flexDirection="column" gap={2}>
            <BusyText color={themeColor + ".main"}>
              {`${status} - ${percentage}%`}
            </BusyText>
            <LinearProgress
              variant="determinate"
              value={percentage}
              color={themeColor as LinearProgressProps["color"]}
            />
          </Box>
        )}
      </CardContent>
      {isLeastBusy && <LeastBusyIcon />}
    </Card>
  );
};

export default LiveCard;
